<template>
  <div>
    <b-card-code title="Update Area" :before-change="validationForm">
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <!-- Name english -->
            <b-col md="4">
              <b-form-group label="Name English" label-for="Name English">
                <validation-provider
                  #default="{ errors }"
                  name="Name English"
                  rules="required"
                >
                  <b-form-input
                    id="Name-English"
                    v-model="data.name.en"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Name Arabic -->
            <b-col md="4">
              <b-form-group label="Name Arabic" label-for="Name Arabic">
                <validation-provider
                  #default="{ errors }"
                  name="Name Arabic"
                  rules="required"
                >
                  <b-form-input
                    id="Name-Arabic"
                    v-model="data.name.ar"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            ​<!-- active -->
            <b-col md="4    ">
              <validation-provider
                #default="{ errors }"
                name="Active"
                rules="required"
              >
                <b-form-group
                  label="Active"
                  label-for="active"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="active"
                    v-model="activeSelect"
                    :state="activeSelect === null ? false : true"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="activeOptions"
                    :selectable="
                      (option) => !option.value.includes('select_value')
                    "
                    label="text"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- city -->
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="City"
                rules="required"
              >
                <b-form-group
                  label="City"
                  label-for="city"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="city"
                    v-model="citySelect"
                    :state="citySelect === null ? false : true"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="cityOptions"
                    :selectable="
                      (option) => !option.value.includes('select_value')
                    "
                    label="text"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            ​
            <!-- submit button -->
            <b-col cols="12">
              <hr />
            </b-col>
            <b-col>
              <b-button
                size="lg"
                variant="primary"
                type="submit"
                @click.prevent="validationForm"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card-code>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul v-for="(values , index) in errors_back" :key="index">
          <li v-for="(value , valIndex) in values" :key="valIndex">{{ value }}</li>
        </ul>
      </div>
    </b-alert>
  </div>
</template>
<script>

import BCardCode from '@core/components/b-card-code'


import { heightTransition } from '@core/mixins/ui/transition'

import { required } from '@validations'
export default {
  components: {
    BCardCode,
   
  },
  mixins: [heightTransition],
  data() {
    return {
      id: 0,
      cityID: '',
      showSpinnerLoad: false,
      citySelect: {
        text: '',
        value: '',
      },
      cityOptions: [
        {
          value: 'select_value',
          text: 'Select Value',
        },
      ],
      data: {
        name: '',
        city_id: '',
        active: '',
      },
      errors_back: '',
      showDismissibleAlert: false,
      required,
      activeSelect: {
        text: '',
        value: '',
      },
      activeOptions: [
        {
          text: 'True',
          value: '1',
        },
        {
          text: 'False',
          value: '0',
        },
      ],
    }
  },
  created() {
    // fetch data of area
    this.id = this.$route.params.id
    axios
      .get('areas/' + this.id)
      .then((result) => {
        const data = result.data.data
        this.data.name = data.name
        this.cityID = data.city.id
        if (data.active == true) {
          this.activeSelect.value = '1'
          this.activeSelect.text = 'True'
        } else {
          this.activeSelect.value = '0'
          this.activeSelect.text = 'False'
        }
        // fetch data of cities
        axios
          .get('cities')
          .then((result) => {
            const data = result.data.data
            for (let ind in data) {
              if (data[ind].id == this.cityID) {
                this.citySelect.value = data[ind].id.toString()
                this.citySelect.text = data[ind].name
              }
              this.cityOptions.push({
                text: data[ind].name,
                value: data[ind].id.toString(),
              })
            }
          })
          .catch((err) => {
            this.errors_back.length = 0
            if (err.response.data.data != null) {
              if (this.isString(err.response.data.data)) {
                this.errors_back.push({
                  error: err.response.data.data,
                })
              } else {
                this.errors_back = err.response.data.data
              }
              this.showDismissibleAlert = true
            } else {
              this.errors_back = []
              this.errors_back.push({
                error: 'internal server error',
              })
              this.showDismissibleAlert = true
            }
          })
      })
      .catch((err) => {
        this.errors_back.length = 0
        if (err.response.data.data != null) {
          if (this.isString(err.response.data.data)) {
            this.errors_back.push({
              error: err.response.data.data,
            })
          } else {
            this.errors_back = err.response.data.data
          }
          this.showDismissibleAlert = true
        } else {
          this.errors_back = []
          this.errors_back.push({
            error: 'internal server error',
          })
          this.showDismissibleAlert = true
        }
      })
  },
  methods: {
    // submit update data to area API
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.data.city_id = this.citySelect.value
          this.data.active = this.activeSelect.value

          axios
            .put('areas/' + this.id, this.data)
            .then((result) => {
              this.$swal({
                position: 'center',
                icon: 'success',
                title: 'Your work has been saved',
                showConfirmButton: false,
                timer: 1500,
              })
              this.$router.back(1)
            })
            .catch((err) => {
              this.$swal({
                position: 'center',
                icon: 'error',
                title: 'Error!',
                showConfirmButton: false,
                timer: 1500,
              })
              this.errors_back.length = 0
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  })
                } else {
                  this.errors_back = err.response.data.data
                }
                this.showDismissibleAlert = true
              } else {
                this.errors_back = []
                this.errors_back.push({
                  error: 'internal server error',
                })
                this.showDismissibleAlert = true
              }
            })
        }
      })
    },
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
  },
}
</script>
​
<style lang="scss">

</style>
​
<style lang="scss" scoped>
// REPETED FORM
.repeater-form {
  transition: 0.35s height;
}
</style>